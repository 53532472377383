<template>
  <div class="section">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input
          label="商家简称"
          clearable
          v-model="searchParam.titleAbbreviation"
        />
        <v-input
          label="商家联系电话"
          clearable
          v-model="searchParam.mobileNum"
        />
        <v-select
          clearable
          :options="businessStatusOps"
          v-model="searchParam.status"
          label="状态"
        />
        <v-input label="商家名称：" clearable v-model="searchParam.title" />
        <v-select
          clearable
          :options="principalTypeOps"
          v-model="searchParam.principalType"
          label="主体类型"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看电话" type="text" @click="getDecPhone(scope.row)" />
        <v-button text="详情" type="text" @click="toView(scope.row)" />
        <v-button
          text="审核"
          v-if="scope.row.auditStatus == 0"
          type="text"
          @click="toAudio(scope.row)"
        />
        <v-button
          text="冻结"
          v-if="scope.row.auditStatus == 1"
          type="text"
          @click="changeFreeze(scope.row, 3)"
        />
        <v-button
          text="解冻"
          v-if="scope.row.auditStatus == 3"
          type="text"
          @click="changeFreeze(scope.row, 5)"
        />
        <v-button text="排序" type="text" @click="sort(scope.row)" />
      </template>
    </v-list>

    <v-dialog
      title="审核"
      v-model="showDialog"
      width="380px"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
    >
      <form-panel
        ref="formPanel"
        :form="form"
        :hasHeader="false"
        :footerShow="false"
      >
        <el-form-item
          label="审核状态"
          :rules="[
            { required: true, message: '请选择审核状态', trigger: 'change' },
          ]"
          prop="auditStatus"
        >
          <v-select
            :options="auditStatusOps"
            v-model="form.auditStatus"
          ></v-select
        ></el-form-item>
      </form-panel>
      <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="(dialogFit = false), (dialogComputed = true)"
              >下一步</el-button
            >
            <el-button type="primary" @click="dialogFit = false"
              >取消</el-button
            >
          </span> -->
    </v-dialog>
    <v-dialog
      class="sort-dialog"
      width="380px"
      v-model="isDialog"
      title="排序"
      @confirm="confirmSort"
    >
      <v-input
        label="排序:"
        type="number"
        placeholder="请输入排序值"
        v-model.number="sortData.sortIndex"
      ></v-input>
    </v-dialog>
    <v-dialog
      title="联系电话"
      v-model="dialogFormVisible"
      width="50%"
      @confirm="doCancel"
    >
      <p>{{ tel }}</p>
    </v-dialog>
  </div>
</template>

<script>
import {
  getShopInfoListURL,
  updateStatusURL,
  sortShop,
  quarterMallGetDecPhoneUrl,
  // auditGoodsInfoURL,
} from "../api.js";
import {
  // buyTypeMap,
  setBuyTypeOps,
  // groupStatusMap,
  setGroupStatusOps,
  principalTypeMap,
  setPrincipalTypeOps,
  businessStatusMap,
  setBusinessStatusOps,
  setAuditStatusOps,
} from "../map.js";
// import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";

export default {
  data() {
    return {
      isDialog: false,
      dialogFormVisible: false,
      tel: "",
      buyTypeOps: setBuyTypeOps(),
      groupStatusOps: setGroupStatusOps(),
      principalTypeOps: setPrincipalTypeOps(),
      businessStatusOps: setBusinessStatusOps(),
      auditStatusOps: setAuditStatusOps().slice(1),
      searchParam: {
        titleAbbreviation: "",
        mobileNum: "",
        title: "",
        status: undefined,
        principalType: undefined,
      },
      tableUrl: getShopInfoListURL,
      headers: [
        {
          prop: "titleAbbreviation",
          label: "商家简称",
        },
        {
          prop: "mobileNum",
          label: "商家联系电话",
        },
        {
          prop: "principalType",
          label: "主体类型",
          formatter(row, prop) {
            return principalTypeMap[row[prop]];
          },
        },
        {
          prop: "title",
          label: "商家名称",
        },
        {
          prop: "total",
          label: "累计交易笔数",
        },
        {
          prop: "insertTime",
          label: "申请时间",
        },
        {
          prop: "auditStatus",
          label: "状态",
          formatter(row, prop) {
            return businessStatusMap[row[prop]];
          },
        },
      ],

      /** v-dialog */
      showDialog: false,
      curItem: null,
      form: {
        auditStatus: undefined,
      },
      sortData: {
        id: "",
        sortIndex: "",
      },
    };
  },
  computed: {
    time: {
      get() {
        return !this.searchParam.beginTime && !this.searchParam.endTime
          ? []
          : [this.searchParam.beginTime, this.searchParam.endTime];
      },
      set(val) {
        [this.searchParam.beginTime, this.searchParam.endTime] = val || [
          "",
          "",
        ];
      },
    },
  },
  methods: {
    doCancel() {
      this.dialogFormVisible = false;
    },
    // 获取商品icon图
    async getDecPhone(row) {
      let params = {
        id: row.id,
      };
      let res = await this.$axios.get(quarterMallGetDecPhoneUrl, { params });
      if (res.code == 200) {
        this.tel = res.data;
        this.dialogFormVisible = true;
      }
    },
    sort(row) {
      this.sortData.id = "";
      this.sortData.sortIndex = "";
      this.sortData.id = row.id;
      this.isDialog = true;
    },
    confirmSort() {
      if (!this.sortData.sortIndex) {
        this.$message.error("请输入排序值");
        return;
      }
      this.$axios
        .post(sortShop, null, {
          params: {
            ...this.sortData,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        })
        .finally(() => {
          this.isDialog = false;
        });
    },
    toAudio(item) {
      this.curItem = item;
      this.form.auditStatus = undefined;
      if (this.$refs.formPanel) {
        this.$refs.formPanel.clearValidate();
      }
      this.showDialog = true;
    },
    toView(data) {
      this.$router.push({
        name: "quarterBusinessForm",
        query: { id: data.id, isView: true },
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "quarterBusinessForm",
        query: { id: data.id },
      });
    },
    // 解冻 冻结
    changeFreeze(data, status) {
      let message = "是否确定解冻？";
      if (status == 3) {
        message = "是否确认冻结？";
      }

      this.$confirm(message, "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(updateStatusURL, null, {
            params: {
              id: data.id,
              status,
            },
          })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    // toCancel(data) {
    //   this.$confirm("你确定要删除吗", "确定", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$axios
    //         .post(`${delGroupInfoURL}`, null, {
    //           params: {
    //             ids: data.id,
    //           },
    //         })
    //         .then((res) => {
    //           if (res.code === 200) {
    //             this.$message.success("删除成功");
    //             this.$refs.list.search();
    //           } else {
    //             this.$message.error(res.msg);
    //           }
    //         });
    //     })
    //     .catch(() => {
    //       // Message.error('禁用失败！');
    //     });
    // },
    /** v-dialog */
    dialogCancel() {
      this.showDialog = false;
    },
    dialogConfirm() {
      const result = this.$refs.formPanel.validate();
      if (!result) {
        return;
      }
      this.$axios
        .post(updateStatusURL, null, {
          params: {
            id: this.curItem.id,
            status: this.form.auditStatus,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
            this.showDialog = false;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
  .sort-dialog {
    .v-control {
      display: flex;
      align-items: center;
      ::v-deep .el-input {
        flex: 1;
      }
    }
  }
}
</style>
